import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavBarLink } from 'ui-components';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {

    navBarlinks: NavBarLink[] = [
        {
            title: 'Members',
            url: null
        },
    ];
    constructor(
        private title: Title,
    ) { }

    ngOnInit() {
        // this.title.setTitle('Not Found');
    }
}
