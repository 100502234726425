import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UIComponentsModule } from 'ui-components';
import { NotFoundComponent } from './not-found/not-found.component';
import { ListiqueAuthModule, ListiqueAuthGuard, ListiqueAuthService } from '@listique/auth';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GenericEntityModule } from 'generic-entity';
import { LoadingDialogModule } from 'loading-dialog';
import { CustomFormsModule } from 'forms';
import { HttpClientModule } from '@angular/common/http';
import { ImageUploadModule } from 'image-upload';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

const appearance: MatFormFieldDefaultOptions = {
    appearance: 'outline'
};

@NgModule({
    declarations: [
        AppComponent,
        NotFoundComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        UIComponentsModule,
        ListiqueAuthModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireAuthModule,
        AngularFireStorageModule,
        AngularFireDatabaseModule,
        AngularFireFunctionsModule,
        AngularFirePerformanceModule,
        BrowserAnimationsModule,
        GenericEntityModule,
        LoadingDialogModule,
        CustomFormsModule,
        HttpClientModule,
        ImageUploadModule
    ],
    providers: [
        ListiqueAuthGuard,
        ListiqueAuthService,
        // { provide: FUNCTIONS_ORIGIN, useValue: 'http://localhost:5000' },
        { provide: REGION, useValue: 'europe-west2' },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: appearance }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
