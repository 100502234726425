import { footer as _footer } from './footer';

export const environment = {
    production: false,
    firebase: {
        apiKey: 'AIzaSyBS9-Kr1EPtpNwF0t0cMi6Ht_iT_YykNYM',
        authDomain: 'listique-site-staging.firebaseapp.com',
        databaseURL: 'https://listique-site-staging.firebaseio.com',
        projectId: 'listique-site-staging',
        storageBucket: 'listique-site-staging.appspot.com',
        messagingSenderId: '1086475982007',
        appId: '1:1086475982007:web:6fbe8585d7824b9e922932',
        measurementId: 'G-6CZW8N891P'
    },
    websiteDomain: 'https://staging.listique.com',
    membersDomain: 'https://staging.members.listique.com',
    footer: _footer
};
