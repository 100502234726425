import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { ListiqueAuthGuard, ListiqueAuthPermissions } from '@listique/auth';

const routes: Routes = [
    {
        path: 'auth/login',
        loadChildren: () => import(`./auth/login/login.module`).then(m => m.LoginModule),
    },
    {
        path: 'partner',
        canActivate: [ListiqueAuthGuard],
        data: {
            permissions: [
                ListiqueAuthPermissions.MEMBERS_AREA,
                ListiqueAuthPermissions.MEMBERS_PARTNER_AREA,
                ListiqueAuthPermissions.ADMIN,
                ListiqueAuthPermissions.SUPER_ADMIN
            ]
        },
        loadChildren: () => import(`./partner-container/partner-container.module`).then(m => m.PartnerContainerModule),
    },
    {
        path: 'auth',
        redirectTo: '/auth/login'
    },
    {
        path: '404',
        component: NotFoundComponent
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/partner/manage'
    },
    {
        path: '**',
        redirectTo: '/partner/manage'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
