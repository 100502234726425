export const footer = {
    col1: {
        title: 'Listique',
        links: [
            {
                title: 'Home',
                url: '{WEBSITE_DOMAIN}/',
                external: true
            },
            {
                title: 'Categories',
                url: '{WEBSITE_DOMAIN}/categories',
                external: true
            },
            {
                title: 'Events',
                url: '{WEBSITE_DOMAIN}/events',
                external: true
            },
            {
                title: 'What is Listique',
                url: '{WEBSITE_DOMAIN}/about',
                external: true
            },
        ],
    },
    col2: {
        title: null,
        links: [
            {
                title: 'Careers',
                url: '{WEBSITE_DOMAIN}/contact',
                external: true
            },
            {
                title: 'Press',
                url: '{WEBSITE_DOMAIN}/contact',
                external: true
            },
            {
                title: 'About Us',
                url: '{WEBSITE_DOMAIN}/about',
                external: true
            },
        ],
    },
    col3: {
        title: 'Everyone',
        links: [
            {
                title: 'Search Partners',
                url: '{WEBSITE_DOMAIN}/partners',
                external: true
            },
            {
                title: 'How it works',
                url: '{WEBSITE_DOMAIN}/how-it-works',
                external: true
            },
        ],
    },
    col4: {
        title: 'Partners',
        links: [
            {
                title: 'Contact Us',
                url: '{WEBSITE_DOMAIN}/contact',
                external: true
            },
            {
                title: 'Apply for partnership',
                url: '{WEBSITE_DOMAIN}/apply',
                external: true
            },
            {
                title: 'Sign In',
                url: '{MEMBERS_DOMAIN}',
                external: true
            },
        ],
    },
    col5: {
        title: 'Find us here',
        socials: [
            {
                type: 'facebook',
                url: 'https://www.facebook.com/listiqueuk',
                label: 'Listique on Facebook',
            },
            {
                type: 'twitter',
                url: 'https://twitter.com/listique_uk',
                label: 'Listique on Twitter',
            },
            {
                type: 'linkedin',
                url: 'https://www.linkedin.com/company/cater-to-london/',
                label: 'Listique on Linkedin',
            },
            {
                type: 'instagram',
                url: 'https://www.instagram.com/listique',
                label: 'Listique on Instagram',
            },
        ],
    },
}